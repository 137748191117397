<template>
  <div id="work-add" style="padding-top: 70rem">
    <el-form ref="form" :model="form" class="form" style="min-height: calc(100vh - 294rem)"
             label-width="150rem">
      <el-form-item prop="class_name" label="申请状态">
        <p>{{ dataCont.status_cn }}</p>
      </el-form-item>
      <el-form-item label="行课类型" prop="class_name">
        <p>{{ dataCont.type_preview }}</p>
      </el-form-item>
      <el-form-item label="课程名称" prop="class_name">
        <p>{{ dataCont.class_name }}</p>
      </el-form-item>
      <el-form-item label="校区" prop="school_id">
        <p>{{ dataCont.school_name }}</p>
      </el-form-item>
      <el-form-item label="上课日期" prop="date">
        <p>{{ dataCont.date }}</p>
      </el-form-item>
      <div class="flex">
        <el-form-item label="上课时间" prop="start_section">
          <p>{{ dataCont.section_cn }}</p>
        </el-form-item>
      </div>
      <el-form-item label="上课教师" prop="teacher">
        <div style="max-width: 700rem;">
          <el-tag
              v-for="(item, index) in dataCont.teachers"
              :key="item"
              style="margin-right: 10rem">
            {{ item }}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item v-if="dataCont.status_cn === '已拒绝'" label="拒绝原因" prop="teacher">
        <p>{{ dataCont.refuse_remark }}</p>
      </el-form-item>
    </el-form>

    <div class="footer-button" v-if="dataCont.options === 'approve'">
      <el-button type="default" @click="popShow = true">拒绝</el-button>
      <el-button type="primary" @click="allow">通过</el-button>
    </div>

    <div class="footer-button" v-if="dataCont.options === 'revoke'">
      <el-button type="default" @click="$router.back()">返回</el-button>
      <el-button type="primary" @click="reback">撤回申请</el-button>
    </div>

    <div class="footer-button" v-if="dataCont.options === 'approve,revoke'">
      <el-button type="default" @click="$router.back()">返回</el-button>
      <el-button type="primary" @click="reback">撤回申请</el-button>
      <el-button type="primary" @click="popShow = true">拒绝</el-button>
      <el-button type="primary" @click="allow">通过</el-button>
    </div>

    <div class="footer-button" v-if="dataCont.options === 'details'">
      <el-button type="primary" @click="$router.back()">返回</el-button>
    </div>

    <el-dialog :visible.sync="popShow" title="拒绝申请" width="800rem">
      <el-form ref="form" :model="form" :rules="rules" label-width="122rem">
        <el-form-item label="请输入拒绝原因" prop="refuse_remark">
          <el-input v-model="form.refuse_remark" :autosize="{ minRows: 6, maxRows: 8 }"
                    show-word-limit maxlength="100"
                    placeholder="请输入拒绝原因" size="small" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="popShow = false">取消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import datePicker from "@/components/DatePicker"

export default {
	_config:{"route":{"path":"details","meta":{"title":"详情"}}},
  data() {
    return {
      loading: false,
      dataCont: '',
      popShow: false,
      form: {
        refuse_remark: ''
      },
      rules: {
        refuse_remark: [{required: true, message: "请输入拒绝原因"}],
      },
    }
  },
  mounted() {
    // this.getSchool()
    this.getData()
  },
  components: {
    datePicker
  },
  methods: {
    getData(){
      this.$_axios2.get('api/attendance/administrative-class-judge/details?id=' + this.$route.query.id).then(res => {
        this.dataCont = res.data.data
      })
    },

    reback(){
      this.$confirm('是否确认撤回申请?','提示',{type:'warning'}).then(res=>{
        this.$_axios2.post('api/attendance/administrative-class-judge/revoke', {id: this.$route.query.id}).then(res => {
          if (res.data.status === 0) {
            this.$message.success({
              message: res.data.msg,
              onClose: () => {
                this.getData()
              }
            })
          }
        })
      });
    },

    allow(){
      this.$confirm('您正在通过行课申请审批，是否确认?','提示',{type:'warning'}).then(res=>{
        this.$_axios2.post('api/attendance/administrative-class-judge/pass', {id: this.$route.query.id}).then(res => {
          if (res.data.status === 0) {
            this.$message.success({
              message: res.data.msg,
              onClose: () => {
                this.getData()
              }
            })
          }
        })
      });
    },

    onSubmit(){
      this.$refs.form.validate(valid => {
        if (valid) {
          this.loading = true
          let params = {id: this.$route.query.id, ...this.form};
          this.$_axios2.post('api/attendance/administrative-class-judge/refuse', params).then(res => {
            if (res.data.status === 0) {
              this.$message.success({
                message: res.data.msg,
                onClose: () => {
                  this.loading = false;
                  this.popShow = false
                  this.getData()
                }
              })
            }
          })
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
#work-add {
  padding-top: 35rem;

  .form {

    font-size: 14rem;
    margin: 0 0 20rem 50rem;

    .el-form-item {
      margin-bottom: 30rem;
    }
  }

  .el-checkbox {
    height: 80rem;
    margin: 0 0 10rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150rem;

    ::v-deep span.el-checkbox__input {
      display: none;
    }
  }

  ::v-deep .el-checkbox.is-checked {
    //background-color: #1d2088;

    .el-checkbox__label {
      color: white;
    }
  }

  .course {
    //text-align: center;
    line-height: 30rem;
    max-height: 353rem;
    width: 100%;
    overflow-y: scroll;
    padding-top: 20rem;

    p {
      line-height: 24rem;
    }

    li {
      padding: 10rem 5rem;
      border-bottom: 1rem solid #eeeeee;
      cursor: pointer;
    }

    li:last-child {
      border-bottom: none;
    }

    li:hover {
      background-color: #eeeeee;
    }

    li.courseActive {
      background-color: #1d2088;
      color: white;
    }
  }

}
</style>
